import React, { Component } from "react";
import "./App.css";
import Home from "./Home";
import {getMessage} from "./util";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";

const queryString = require("query-string");

class App extends Component {
	render() {
		return (
			<Router>
				<div>
					<Route path="/" exact component={NoElection} />
					<Route path="/anef" exact component={MyRedirect} />
					<Route path="/renca" exact component={MyRedirect} />
					<Route path="/anef-maule" exact component={MyRedirect} />
					<Route path="/parque" exact component={MyRedirect} />
					<Route path="/:election_id" exact component={Home} />
				</div>
			</Router>
		);
	}
}

class MyRedirect extends Component {
	componentWillMount() {
		console.log(this.props);
		switch (this.props.match.path) {
			case "/anef":
				window.location.href='https://bulletinold.evoting.cl/anef';
				break;
			case "/anef-maule":
				window.location.href='https://bulletinold.evoting.cl/anef-maule';
				break;
			case "/renca":
				window.location.href='https://bulletinold.evoting.cl/renca';
				break;
			case "/parque":
				window.location.href='https://bulletinold.evoting.cl/parque';
				break;
			default:
				break;
		}
	}

	render() {
		return <NoElection />;
	}
}

class NoElection extends Component {
	render() {
		const parsed = queryString.parse(this.props.location.search);
		if (!parsed.id) {
			return (
				<div className="container">
					<h3>{getMessage('title',this.props.messages)}</h3>
					<p>
					{getMessage('excerpt',this.props.messages)}
					</p>
				</div>
			);
		} else {
			return <Redirect to={"/" + parsed.id} />;
		}
	}
}

export default App;