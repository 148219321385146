import React, {Component, Fragment} from "react";
import { Bar } from "react-chartjs-2";
import axios from "axios";
import LoadingSpinner from "./LoadingSpinner";
import {getMessage} from "./util";

class VotesByHour extends Component {
  constructor(props) {
    super(props);
    this.state = {
      votes: null,
      loading: false
    };
  }

  componentDidMount() {
    this.loadVotesByHour(this.props.election_id);
  }
  loadVotesByHour = election_id => {
    this.setState({ loading: true }, () =>
      axios({
        method: "get",
        url:
          process.env.REACT_APP_BACKUP_URL +
          "/" +
          this.props.election_id +
          "/votesByHour.json"
      })
        .then(response => {
          this.setState({
            loading: false,
            votes: Object.entries(response.data.hourly).map(function(v) {
              return { x: 1000 * parseInt(v[0]), y: v[1].electronic_voted };
            })
          });
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            axios({
              method: "get",
              url:
                process.env.REACT_APP_API_URL +
                "/election/" +
                election_id +
                "/votesByHour"
            })
              .then(response => {
                this.setState({
                  loading: false,
                  votes: Object.entries(response.data.hourly).map(function(v) {
                    return {
                      x: 1000 * parseInt(v[0]),
                      y: v[1].electronic_voted
                    };
                  })
                });
              })
              .catch(error => {
                this.setState({ loading: false });
              });
          } else {
            this.setState({ loading: false });
          }
        })
    );
  };

  render() {
    if (this.state.votes === null) {
      return <div>{this.state.loading && <LoadingSpinner />}</div>;
    } else {
      const data = {
        labels: [getMessage('votes_hour_title',this.props.messages)],
        datasets: [
          {
            label: "Votos Electrónicos ",
            backgroundColor: "#C6CCD4",
            data: this.state.votes,

          }
        ]
      };

      const now = new Date().getTime();

      const chartOptions = {
        scales: {
          xAxes: [
            {
              type: "time",
              time: {
                parser: "x",
                unit: "hour",
                min: this.state.votes[0].x - 1800000,
                max:
                  Math.min(
                    now - (now % 3600000),
                    this.state.votes[this.state.votes.length - 1].x
                  ) + 1800000
              },
              distribution: "series"
            }
          ],
          yAxes: [
            {
              ticks: {
                min: 0
              },
              distribution: "series"
            }
          ]
        }
      };

      return (
          <Fragment>

          {this.state.votesByHour !== null && (
            <Fragment>
              <div className="row justify-content-center EvColor text-white">
                <div className="col-12 col-sm-11 col-md-9 col-lg-7  p-3">
                  <h4>{getMessage('votes_hour_title',this.props.messages)}</h4>
                </div>
              </div>
              <br/>
              <div className="row justify-content-center ">
                <div className="col-12 col-sm-11 col-md-9 col-lg-7 EvColorGrisLight">
                  <Bar data={data} options={chartOptions} />
                </div>
              </div>
            </Fragment>
          )}
          </Fragment>
      );
    }
  }
}

export default VotesByHour;