import React, {Component, Fragment} from "react";
import "./ResultsTable.css";
import axios from "axios";
import LoadingSpinner from "./LoadingSpinner";
import {getMessage} from "./util";

class Questions extends Component {

    render() {
        const questions = this.props.district ? this.props.questions.filter(q =>
            q.districts.reduce((acc, element) => {
                return (
                    acc || (element.district_id === this.props.district.id)
                );
            }, false)
        ) : this.props.questions;


        return (
            <Fragment>
                {questions.map(q => (
                    <QuestionRow
                        key={q.question_id}
                        question={q}
                        factor={this.props.factor}
                        votantes={this.props.votantes}
                        district={this.props.district}
                        districts={this.props.districts}
                        messages={this.props.messages}
                    />
                ))}
            </Fragment>
        );
    }
}

class QuestionRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true,
            orden: {
                criterio: "absolute_position",
                ascendente: 1
            }
        };
    }

    doCollapse = event => {
        this.setState({collapsed: !this.state.collapsed});
    };

    setOrden = (criterio, ascendente) => {
        this.setState({

            orden: {
                criterio: criterio,
                ascendente: ascendente
            }

        });
    }


    render() {

        const q = this.props.question;
        const suma = this.props.district ? q.districts.filter(d => d.district_id === this.props.district.id)[0].total : q.total;
        /*const dist = this.props.district ? (this.props.votantes.districts[this.props.district.id].weighted.electronic_voted +
            this.props.votantes.districts[this.props.district.id].weighted.ready +
            this.props.votantes.districts[this.props.district.id].weighted.manual_voted +
            this.props.votantes.districts[this.props.district.id].weighted.registered)
            :
            (this.props.votantes.weighted.electronic_voted + this.props.votantes.weighted.ready +
                this.props.votantes.weighted.manual_voted + this.props.votantes.weighted.registered);*/
        const totals = this.props.district ? q.districts.filter(d => d.district_id === this.props.district.id)[0].choices : q.totals;
        const blanks = this.props.district ? q.districts.filter(d => d.district_id === this.props.district.id)[0].blanks : q.blanks;
        const nulls = this.props.district ? q.districts.filter(d => d.district_id === this.props.district.id)[0].nulls : q.nulls;
        const lists = this.props.district ? q.districts.filter(d => d.district_id === this.props.district.id)[0].lists : q.lists;

        const question_district = q.districts.map(qd => this.props.districts[qd.district_id])
        const total_voters_district = question_district.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.weighted.electronic_voted + currentValue.weighted.ready +
                currentValue.weighted.manual_voted + currentValue.weighted.registered

        }, 0 )

        let orden = (a, b) => {
            return this.state.orden.ascendente * (a.relative_position - b.relative_position);
        }

        let ordenLista = (a, b) => {
            return this.state.orden.ascendente * (a.relative_position - b.relative_position);
        }

        switch (this.state.orden.criterio) {
            case "totales":
                orden = (a, b) => {
                    return this.state.orden.ascendente * (q.totals[a.absolute_position] - q.totals[b.absolute_position]);
                }
                ordenLista = (a, b) => {
                    return this.state.orden.ascendente * (a.total - b.total);
                }
                break;
            case "opcion":
                orden = (a, b) => {
                    return this.state.orden.ascendente * (b.name.localeCompare(a.name));
                }
                ordenLista = (a, b) => {

                    return this.state.orden.ascendente * (b.name.localeCompare(a.name));
                }
                break;
            case "papeleta":
                orden = (a, b) => {
                    return this.state.orden.ascendente * (a.relative_position - b.relative_position);
                }

                break;
            default:
                break;
        }
        return (
            <Fragment>
                <div className="row mt-4">
                    <div className="table-responsive">
                        <table
                            className="table table-bordered  table-hover  rounded">
                            <tbody>

                            <tr>
                                <th colSpan="4" className="EvColorGrisLight">

                                    {q.name}
                                    {Object.keys(this.props.districts).length > 1 &&
                                    <small> ({q.districts.length === 1?q.districts[0].name:(this.props.district ? this.props.district.name : getMessage('default_district_result',this.props.messages))})</small>
                                    }
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    <div className="row">

                                        <div className="col-12 col-sm-4">
                                            {getMessage('option',this.props.messages)}
                                        </div>
                                        <div className="col-12 col-sm-8">
                                            <div className="row justify-content-center">
                                                <div className="col-9 col-sm-6 m-0 p-0 ">
                                                    <button className="btn btn-link p-0 m-0"
                                                            onClick={() => this.setOrden("papeleta", 1)}>[{getMessage('option_original_order',this.props.messages)}]
                                                    </button>
                                                </div>
                                                <div className="col-4 col-sm-3 m-0 p-0">
                                                    <button className="btn btn-link p-0 m-0"
                                                            onClick={() => this.setOrden("opcion", 1)}>[{getMessage('option_order_desc',this.props.messages)}]
                                                    </button>
                                                </div>
                                                <div className="col-4 col-sm-3 m-0 p-0">
                                                    <button className="btn btn-link p-0 m-0"
                                                            onClick={() => this.setOrden("opcion", -1)}>[{getMessage('option_order_asc',this.props.messages)}]
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </th>
                                <th>
                                    <div className="row m-1">

                                        <div className="col-12">

                                            {q.max_options > 1 ? "Preferencias" : "Votos"}
                                        </div>
                                        <div className="col-12">
                                            <div className="row justify-content-center">
                                                <div className="col-6 col-sm-6 m-0 p-0">
                                                    <button className="btn btn-link p-0 m-0"
                                                            onClick={() => this.setOrden("totales", 1)}>[{getMessage('order_asc',this.props.messages)}]
                                                    </button>
                                                </div>
                                                <div className="col-6 col-sm-6 m-0 p-0">
                                                    <button className="btn btn-link p-0 m-0"
                                                            onClick={() => this.setOrden("totales", -1)}>[{getMessage('order_desc',this.props.messages)}]
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </th>
                                <th>{this.props.district && Object.keys(this.props.districts).length > 1 ? getMessage('cast_district_percentage',this.props.messages) : getMessage('cast_percentage',this.props.messages)}</th>
                                <th>{this.props.district && Object.keys(this.props.districts).length > 1 ? getMessage('electoral_roll_district_percentage',this.props.messages) : getMessage('electoral_roll_percentage',this.props.messages)} </th>

                            </tr>


                            {lists.sort(ordenLista).map(l => (
                                <Fragment key={l.name}>
                                    <tr>
                                        <th>{l.name}</th>
                                        <td className="text-center">{l.total.toLocaleString(navigator.language, {
                                            maximumFractionDigits: 0, minimumFractionDigits: 0
                                        })}</td>
                                        <td className="text-center"

                                            key={"porcentajeX-" + q.question_id + "-" + l.name}>

                                            {(suma + blanks + (nulls * q.max_options)) === 0 ? '-' : (
                                                100 * l.total / (suma + blanks + (nulls * q.max_options))
                                            ).toLocaleString(navigator.language, {
                                                maximumFractionDigits: 2, minimumFractionDigits: 2
                                            })}
                                        </td>

                                        <td className="text-center"
                                            key={"porcentajeY-" + q.question_id + "-" + l.name}>
                                            {(
                                                100 * l.total / (total_voters_district * q.max_options)
                                            ).toLocaleString(navigator.language, {
                                                maximumFractionDigits: 2, minimumFractionDigits: 2
                                            })}
                                        </td>
                                    </tr>
                                    {l.choices.sort(orden).map(c => (
                                        <tr className="" key={"Q-" + q.question_id + "-" + c.absolute_position}>

                                            <td>{c.name}</td>
                                            <td className="text-center"
                                                key={"total-" + q.question_id + "-" + c.absolute_position}>
                                                {(
                                                    totals[c.absolute_position] / this.props.factor
                                                ).toLocaleString('de-DE', navigator.language, {
                                                    maximumFractionDigits: 2
                                                })}
                                            </td>
                                            <td className="text-center"
                                                key={"porcentajeX-" + q.question_id + "-" + c.absolute_position}>
                                                {(suma + blanks + (nulls * q.max_options)) === 0 ? '-' : (
                                                    100 * totals[c.absolute_position] / (suma + blanks + (nulls * q.max_options))
                                                ).toLocaleString(navigator.language, {
                                                    maximumFractionDigits: 2, minimumFractionDigits: 2
                                                })}
                                            </td>

                                            <td className="text-center"
                                                key={"porcentajeY-" + q.question_id + "-" + c.absolute_position}>
                                                {(
                                                    100 * totals[c.absolute_position] / (total_voters_district * q.max_options)
                                                ).toLocaleString(navigator.language, {
                                                    maximumFractionDigits: 2, minimumFractionDigits: 2
                                                })}
                                            </td>
                                        </tr>
                                    ))}
                                </Fragment>
                            ))}

                            {q.lists.length > 0 && q.choices.length > 0 && <tr>
                                <th colSpan={4}>
                                    {getMessage('unlisted_choices',this.props.messages)}
                                </th>
                            </tr>}

                            {q.choices.sort(orden).map(c => (
                                <tr className="" key={"Q-" + q.question_id + "-" + c.absolute_position}>

                                    <td>{c.name}</td>
                                    <td className="text-center"
                                        key={"total-" + q.question_id + "-" + c.absolute_position}>
                                        {(
                                            totals[c.absolute_position] / this.props.factor
                                        ).toLocaleString('de-DE', navigator.language, {
                                            maximumFractionDigits: 2
                                        })}
                                    </td>
                                    <td className="text-center"
                                        key={"porcentajeX-" + q.question_id + "-" + c.absolute_position}>
                                        {(suma + blanks + (nulls * q.max_options)) === 0 ? '-' : (
                                            100 * totals[c.absolute_position] / (suma + blanks + (nulls * q.max_options))
                                        ).toLocaleString(navigator.language, {
                                            maximumFractionDigits: 2, minimumFractionDigits: 2
                                        })}
                                    </td>

                                    <td className="text-center"
                                        key={"porcentajeY-" + q.question_id + "-" + c.absolute_position}>
                                        {(
                                            100 * totals[c.absolute_position] / (total_voters_district * q.max_options)
                                        ).toLocaleString(navigator.language, {
                                            maximumFractionDigits: 2, minimumFractionDigits: 2
                                        })}
                                    </td>
                                </tr>
                            ))}

                            <tr key={"Q-" + q.question_id + "-blancos"}>
                                <th>{getMessage('blanks',this.props.messages)}</th>

                                <th className="text-center">{(blanks / this.props.factor).toLocaleString(navigator.language)}</th>
                                <th className="text-center">
                                    {(suma + blanks + (nulls * q.max_options)) === 0 ? '-' : (
                                        100 * blanks / (suma + blanks + (nulls * q.max_options))
                                    ).toLocaleString(navigator.language, {
                                        maximumFractionDigits: 2, minimumFractionDigits: 2
                                    })}
                                </th>
                                <th className="text-center">{(100 * blanks / (total_voters_district * q.max_options)).toLocaleString(
                                    navigator.language,
                                    {maximumFractionDigits: 2, minimumFractionDigits: 2}
                                )}</th>
                            </tr>

                            <tr key={"Q-" + q.question_id + "-nulos"}>

                                <th>{getMessage('nulls',this.props.messages)} {q.max_options > 1 ? (nulls === 1 ? "(" + (nulls / this.props.factor).toLocaleString(

                                    navigator.language,
                                    {maximumFractionDigits: 2}
                                ) + " Voto)" : nulls === 0 ? "" : "(" + (nulls / this.props.factor).toLocaleString(
                                    navigator.language,
                                    {maximumFractionDigits: 2}
                                ) + " Votos)") : ""}</th>
                                <th className="text-center">{(nulls / this.props.factor * q.max_options).toLocaleString(
                                    'de-DE', navigator.language,
                                    {maximumFractionDigits: 2}
                                )}</th>
                                <th className="text-center">
                                    {(suma + blanks + (nulls * q.max_options)) === 0 ? '-' : (
                                        (100 * nulls * q.max_options) / (suma + blanks + (nulls * q.max_options))
                                    ).toLocaleString(navigator.language, {
                                        maximumFractionDigits: 2, minimumFractionDigits: 2
                                    })}
                                </th>
                                <th className="text-center">{(100 * nulls / total_voters_district).toLocaleString(
                                    navigator.language,
                                    {maximumFractionDigits: 2, minimumFractionDigits: 2}
                                )}</th>
                            </tr>
                            <tr className="thead-light text-center">

                                <th>{getMessage('totals',this.props.messages)}</th>
                                <th>{((suma + blanks + (nulls * q.max_options)) / this.props.factor).toLocaleString('de-DE', navigator.language)}</th>
                                <th>{(suma + blanks + (nulls * q.max_options)) === 0 ? '-' : ((suma + blanks + (nulls * q.max_options)) / (suma + blanks + (nulls * q.max_options))) * 100}</th>
                                <th>{(((suma + blanks + (nulls * q.max_options)) / (total_voters_district * q.max_options)) * 100).toLocaleString(

                                    navigator.language,
                                    {maximumFractionDigits: 2, minimumFractionDigits: 2}
                                )}</th>
                            </tr>

                            {this.state.collapsed === false && (
                                <Districts  question={q} factor={this.props.factor}/>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Fragment>
        );
    }
}

class Districts extends Component {
    render() {
        var q = this.props.question;
        return (
            <Fragment>
                {q.districts.map(d => (
                    <DistrictRow
                        district={d}
                        question={q}
                        key={d.district_id}
                        factor={this.props.factor}
                        messages={this.props.messages}
                    />
                ))}
            </Fragment>
        );
    }
}

class DistrictRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true
        };
    }

    doCollapse = event => {
        this.setState({collapsed: !this.state.collapsed});
    };

    render() {
        var d = this.props.district;
        var q = this.props.question;
        return (
            <Fragment>
                <tr className="district-row" onClick={this.doCollapse}>
                    <td>{d.name}</td>
                    {q.choices.map(c => (
                        <td
                            key={
                                "D-" +
                                d.district_id +
                                "-" +
                                q.question_id +
                                "-" +
                                c.absolute_position
                            }
                        >
                            {(
                                d.choices[c.absolute_position] / this.props.factor
                            ).toLocaleString(navigator.language, {
                                maximumFractionDigits: 2
                            })}
                        </td>
                    ))}
                    <td>
                        {(d.blanks / this.props.factor).toLocaleString(navigator.language, {
                            maximumFractionDigits: 2
                        })}
                    </td>
                    <td>
                        {(d.nulls / this.props.factor).toLocaleString(navigator.language, {
                            maximumFractionDigits: 2
                        })}
                    </td>
                </tr>

                {this.state.collapsed === false && (
                    <Ballotboxes
                        district={d}
                        question={q}
                        factor={this.props.factor}
                        key={"D-" + d.district_id + "-" + q.question_id}
                    />
                )}
            </Fragment>
        );
    }
}

class Ballotboxes extends Component {
    render() {
        var d = this.props.district;
        var q = this.props.question;
        return (
            <Fragment>
                {d.ballotboxes.map(bx => (
                    <BallotboxRow
                        district={d}
                        question={q}
                        factor={this.props.factor}
                        key={
                            "BX-" +
                            d.district_id +
                            "-" +
                            q.question_id +
                            "-" +
                            bx.ballotbox_id
                        }
                        ballotbox={bx}
                    />
                ))}
            </Fragment>
        );
    }
}

class BallotboxRow extends Component {
    render() {
        var d = this.props.district;
        var q = this.props.question;
        var bx = this.props.ballotbox;
        return (
            <tr
                key={"D-" + d.district_id + "-" + bx.ballotbox_id + "-" + q.question_id}
                className="ballotbox-row"
            >
                <td>{bx.name}</td>
                {q.choices.map(c => (
                    <td
                        key={
                            "D-" +
                            d.district_id +
                            "-" +
                            bx.ballotbox_id +
                            "-" +
                            q.question_id +
                            "-" +
                            c.absolute_position
                        }
                    >
                        {(
                            bx.choices[c.absolute_position] / this.props.factor
                        ).toLocaleString(navigator.language, {maximumFractionDigits: 2})}
                    </td>
                ))}
                <td>
                    {(d.blanks / this.props.factor).toLocaleString(navigator.language, {
                        maximumFractionDigits: 2
                    })}
                </td>
                <td>
                    {(d.nulls / this.props.factor).toLocaleString(navigator.language, {
                        maximumFractionDigits: 2
                    })}
                </td>
            </tr>
        );
    }
}

class ResultsTable extends Component {
    constructor(props) {
        super(props);

        let d = null;

        if ((Object.keys(this.props.districts).length === 1)) {
            d = this.props.districts[Object.keys(this.props.districts)[0]];
            d.id = Object.keys(this.props.districts)[0];
        }
        this.state = {
            results: null,
            factor: null,
            loading: false,
            district: d
        };
    }

    getLists = async (election_id, district_id, question_id) => {


        try {
            const response =  await axios({
                method: "get",
                url:
                    process.env.REACT_APP_BACKUP_URL +
                    "/" +
                    this.props.election_id +
                    "/district/" + district_id+".json"
            });

            const result = response.data.questions.filter(q => q._id === question_id)[0].lists.map(l => {

                return ({
                    name: l.name,
                    choices: l.choices.map(c => c.absolute_position),
                    relative_position: l.relative_position
                })

            });
            return (result);

        } catch (error) {
            const response = await axios({
                method: "get",
                url:
                    process.env.REACT_APP_API_URL +
                    "/election/" +
                    election_id +
                    "/district/" + district_id
            });

            const result = response.data.questions.filter(q => q._id === question_id)[0].lists.map(l => {

                return ({
                    name: l.name,
                    choices: l.choices.map(c => c.absolute_position),
                    relative_position: l.relative_position
                })

            });
            return (result);
        }


    }

    setData = async (results) => {
        const questions = await Promise.all(Object.keys(results.questions).map(async q => {
            const question = results.questions[q];

            // I got the choices grouped by lists
            const lists = await this.getLists(this.props.election_id, Object.keys(question.districts)[0], q);

            question.lists = lists.map(l => {
                const choices = question.choices.filter(c => {
                    return (l.choices.includes(c.absolute_position));
                })

                return ({
                    name: l.name,
                    choices: choices
                });

            });


            // I need this variable to identify options that are not in lists
            const out_list = lists.reduce((acc, elem) => {
                elem.choices.forEach(c => {
                        acc.push(c);
                    }
                )
                return (acc);
            }, []);

            question.choices = question.choices.filter(c => {
                return (!out_list.includes(c.absolute_position))
            }).sort((a, b) => {
                if (a.relative_position < b.relative_position) return -1;
                if (a.relative_position === b.relative_position) {
                    if (a.absolute_position < b.absolute_position) return -1;
                    if (a.absolute_position === b.absolute_position) return 0;
                    return 1;
                }
                return 1;
            });

            question.totals = {};
            question.total = 0;
            question.districts = Object.keys(question.districts).map(d_id => {
                var district = question.districts[d_id];
                district.choices = {};
                district.ballotboxes = Object.keys(district.ballotboxes).map(bx_id => {
                    var ballotbox = district.ballotboxes[bx_id];
                    var choices = {};

                    ballotbox.choices.forEach(bt => {
                        district.choices[bt.absolute_position] = district.choices[
                            bt.absolute_position
                            ]
                            ? district.choices[bt.absolute_position] + bt.total
                            : bt.total;

                        question.totals[bt.absolute_position] = question.totals[
                            bt.absolute_position
                            ]
                            ? question.totals[bt.absolute_position] + bt.total
                            : bt.total;

                        question.total += bt.total;
                        choices[bt.absolute_position] = bt.total;
                        district.total = district.total
                            ? district.total + bt.total
                            : bt.total;



                    });

                    district.blanks = district.blanks
                        ? district.blanks + ballotbox.blanks
                        : ballotbox.blanks;
                    district.nulls = district.nulls
                        ? district.nulls + ballotbox.nulls
                        : ballotbox.nulls;
                    question.blanks = question.blanks
                        ? question.blanks + ballotbox.blanks
                        : ballotbox.blanks;
                    question.nulls = question.nulls
                        ? question.nulls + ballotbox.nulls
                        : ballotbox.nulls;

                    ballotbox.choices = choices;
                    return ballotbox;
                });
                district.ballotboxes.forEach(bx => {
                    if (bx.paper) {
                        Object.keys(bx.paper).forEach(dp_id => {
                            Object.keys(bx.paper[dp_id].ballotboxes).forEach(bx_id => {
                                var ballotbox = bx.paper[dp_id].ballotboxes[bx_id];
                                ballotbox.choices.forEach(bt => {
                                    district.choices[bt.absolute_position] = district.choices[
                                        bt.absolute_position
                                        ]
                                        ? district.choices[bt.absolute_position] + bt.total
                                        : bt.total;

                                    question.totals[bt.absolute_position] = question.totals[
                                        bt.absolute_position
                                        ]
                                        ? question.totals[bt.absolute_position] + bt.total
                                        : bt.total;

                                    question.total += bt.total;
                                });
                                district.blanks = district.blanks
                                    ? district.blanks + ballotbox.blanks
                                    : ballotbox.blanks;
                                district.nulls = district.nulls
                                    ? district.nulls + ballotbox.nulls
                                    : ballotbox.nulls;
                                question.blanks = question.blanks
                                    ? question.blanks + ballotbox.blanks
                                    : ballotbox.blanks;
                                question.nulls = question.nulls
                                    ? question.nulls + ballotbox.nulls
                                    : ballotbox.nulls;


                            });
                        });
                    }
                })

                // compute total by list by district

                district.lists = [];
                question.lists.forEach(l => {
                    const new_l = {
                        total: l.choices.reduce((acc, c) => {
                            return (acc + district.choices[c.absolute_position]);
                        }, 0),
                        name: l.name,
                        choices: l.choices
                    };
                    district.lists.push(new_l);
                });


                return district;
            });

            question.lists.forEach(l => {

                l.total = l.choices.reduce((acc, c) => {
                    return (acc + question.totals[c.absolute_position]);
                }, 0);

            });

            return question;
        }));
        results.questions = questions;

        this.setState({
            results: results,
            factor: results.factor,
            loading: false
        });

    };

    addManual = () => {
        this.state.results.questions.forEach(q => {
            q.districts.forEach(d => {

            })
        })
    }


    async componentDidMount() {
        await this.setState({loading: true});
        try {
            try {
                const response = await axios.get(
                    process.env.REACT_APP_BACKUP_URL +
                    "/" +
                    this.props.election_id +
                    "/results.json"
                );
                await this.setData(response.data);
                return;
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    const response = await axios({
                        method: "get",
                        url:
                            process.env.REACT_APP_API_URL +
                            "/election/" +
                            this.props.election_id +
                            "/results"
                    });
                    await this.setData(response.data);
                    return;
                }


            }
        } catch (error) {
            await this.setState({loading: false});
        }
    }

    setDistrict = (event) => {
        const value = event.target.options[event.target.selectedIndex].value;
        if (value === "0") {
            this.setState({
                district: null
            });
        } else {
            let d = this.props.districts[event.target.options[event.target.selectedIndex].value];
            d.id = event.target.options[event.target.selectedIndex].value;
            this.setState({
                district: d
            });
        }
    };

    render() {
        if (this.state.results === null) {
            return <div>{this.state.loading && <LoadingSpinner/>}</div>;
        } else {
            return (
                <Fragment>
                    <div className="row EvColor text-white p-2 justify-content-center">
                        <div className="col-6 col-sm-6 col-md-5 col-lg-4">



                            <h4>{getMessage('section_results',this.props.messages)}</h4>


                        </div>
                        <div className="col-6 col-sm-5 col-md-4 col-lg-3 text-right">

                            {Object.keys(this.props.districts).length > 1 && (
                                <select onChange={(e) => this.setDistrict(e)}>

                                    <option disabled>{getMessage('district',this.props.messages)}</option>
                                    <option value="0">{getMessage('all_districts',this.props.messages)}</option>
                                    {Object.keys(this.props.districts).sort((a, b) => this.props.districts[a].name.localeCompare(this.props.districts[b].name)).map(d => (
                                        <option value={d} key={d}>{this.props.districts[d].name}</option>

                                    ))
                                    }
                                </select>
                            )
                            }
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-12 col-sm-11 col-md-9 col-lg-7">

                            {this.state.results && <Questions
                                messages={this.props.messages}
                                questions={this.state.results.questions}
                                factor={this.state.factor}
                                votantes={this.props.votantes}
                                district={this.state.district}
                                districts={this.props.districts}
                            />}
                        </div>
                    </div>
                </Fragment>

            );
        }
    }
}

export default ResultsTable;